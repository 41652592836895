/* eslint-disable eqeqeq */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  firstValueFrom,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { LocalStorageService } from '../../core/services/local-storage.service';
import {
  APP_PERMISSION_PREFIXES,
  CommonPolicies,
  LocalStorageKey,
  PermissionModules,
  ZatcaAppPermissions,
} from '../../shared/constants';
import { FeatureFlagService } from '../../shared/services/types/feature-flag.service.interface';
import { FeatureFlagEnum } from '../../shared/constants/feature-flag.constants';
import { legacyPermissionstoNewPermissions } from '../../shared/constants/permissions.constants';
import { PermissionHelperService } from '../../shared/services/permission.helper.service';
import {
  LocationFields,
  VariantLocationFields,
} from '../../inventory/products/components/create/product/simple-product/types';

export interface PermissionDTO {
  id: number;
  permission: string;
}

interface PermissionsV2Response {
  roles: string[];
  permissions: PermissionDTO[];
}

const API_URL = '/api';

/**
 * @deprecated use PermissionHelperServiceV2 instead
 */
@Injectable()
export class PermissionServiceV2 {
  private userPermissionsSubject$ = new BehaviorSubject<PermissionDTO[]>(
    undefined,
  );

  isPermissionV2Enabled: boolean;

  // these are the keys of APP_PERMISSION_PREFIXES
  readonly legacyAppPrefixesMapping = {
    woo_commerce: PermissionModules.WooCommerce,
    magento: PermissionModules.Magento,
    zid: PermissionModules.Zid,
    qoyod: PermissionModules.Qoyod,
    salla: PermissionModules.Salla,
    point_of_sale: PermissionModules.Pos,
    promotion_management: PermissionModules.Promotions,
    accounting_app: PermissionModules.Accounting,
    expenses: PermissionModules.Accounting,
    zatca: PermissionModules.Zatca,
    bonat: PermissionModules.Bonat,
  };

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private featureFlagService: FeatureFlagService,
    private permissionHelperService: PermissionHelperService,
  ) {}

  public loadPermissions(): Observable<PermissionsV2Response> {
    return this.http
      .get<PermissionsV2Response>(
        `${API_URL}/mims-extended/merchants/permissions`,
      )
      .pipe(catchError(() => of({ roles: [], permissions: [] })));
  }

  public getUserPermissions(): Observable<PermissionDTO[]> {
    if (this.userPermissionsSubject$.value != undefined) {
      return of(this.userPermissionsSubject$.value);
    }
    return this.loadPermissions().pipe(
      map((res) => {
        const { permissions } = res;
        this.userPermissionsSubject$.next(permissions);
        this.localStorageService.setItem(
          LocalStorageKey.Permissions,
          permissions,
        );
        return permissions;
      }),
    );
  }

  public async refreshPermissions(): Promise<void> {
    const res = await firstValueFrom(this.loadPermissions());
    const { permissions } = res;
    this.userPermissionsSubject$.next(permissions);
  }

  getPermissionByModuleName(
    moduleName: PermissionModules,
  ): Observable<string[]> {
    return this.getUserPermissions().pipe(
      tap(async (_permissions) => {
        if (this.isPermissionV2Enabled == undefined) {
          this.isPermissionV2Enabled = await firstValueFrom(
            this.featureFlagService.isEnabled(FeatureFlagEnum.PermissionsV2),
          );
        }
      }),
      switchMap((permissions) => {
        if (permissions && permissions.length > 0) {
          const mactchingPermissions = permissions
            .filter((perm) => perm.permission.startsWith(moduleName))
            .map((per) => per.permission);
          return of(mactchingPermissions);
        }
        return of([]);
      }),
    );
  }

  hasAtleastOnePermission(
    isPermissionsV2Enabled: boolean,
    requiredPermissions: string[],
  ): boolean {
    if (isPermissionsV2Enabled === undefined) {
      isPermissionsV2Enabled = this.isPermissionV2Enabled;
    }
    if (!isPermissionsV2Enabled) {
      const updatedPermissions = this.updatePermissions(
        isPermissionsV2Enabled,
        requiredPermissions,
      );
      return !!this.permissionHelperService.anyOnePermissionExist(
        updatedPermissions,
      );
    }
    const permissions = this.userPermissionsSubject$.value;
    if (!(permissions && permissions.length > 0)) return false;
    const userPermissions = permissions.map((perm) => perm.permission);
    const updatedPermissions = this.updatePermissions(
      isPermissionsV2Enabled,
      userPermissions,
    );
    const updatedRequiredPermissions = this.updatePermissions(
      isPermissionsV2Enabled,
      requiredPermissions,
    );
    for (const reqPerm of updatedRequiredPermissions) {
      if (updatedPermissions.includes(reqPerm)) {
        return true;
      }
    }
    return false;
  }

  isAppPermissionExistV2(moduleName: string, permission: string): boolean {
    if (this.isPermissionV2Enabled) {
      const permissions = this.userPermissionsSubject$.value;
      const userPermissionArray = permissions.map((perm) => perm.permission);
      const updatedPermission = this.updatePermissions(
        this.isPermissionV2Enabled,
        [permission],
      )[0];

      const oldPrefix = APP_PERMISSION_PREFIXES.find(
        (app) => moduleName.toLowerCase() === app.name.toLowerCase(),
      );
      let appPrefix = '';
      if (oldPrefix && oldPrefix.prefix)
        appPrefix = this.legacyAppPrefixesMapping[oldPrefix.prefix];

      return userPermissionArray.includes(`${appPrefix}:${updatedPermission}`);
    }
    return !!this.permissionHelperService.isAppPermissionExist(
      moduleName,
      permission,
    );
  }

  updatePermissions(
    isPermissionsV2Enabled: boolean,
    permissions: string[],
  ): string[] {
    if (isPermissionsV2Enabled) {
      return this.updatePermissionsFromV1toV2(permissions);
    }
    return this.updatePermissionsFromV2toV1(permissions);
  }

  private updatePermissionsFromV1toV2(permissions: string[]): string[] {
    const getUpdatedPermission = (permission: string): string =>
      legacyPermissionstoNewPermissions[permission] || permission;

    if (permissions && permissions.length > 0) {
      return permissions.map((permission) => getUpdatedPermission(permission));
    }
    return [];
  }

  private updatePermissionsFromV2toV1(permissions: string[]): string[] {
    const newPermissionstoLegacyPermissions = this.swapObject(
      legacyPermissionstoNewPermissions,
    );
    const getLegacyPermission = (permission: string): string =>
      newPermissionstoLegacyPermissions[permission] || permission;

    if (permissions && permissions.length > 0) {
      return permissions.map((permission) => getLegacyPermission(permission));
    }
    return [];
  }

  private swapObject(obj: { [key: string]: string }): {
    [key: string]: string;
  } {
    const swapped = {};
    Object.keys(obj).forEach((key) => {
      swapped[obj[key]] = key;
    });
    return swapped;
  }

  getAppReadPermissionsListsV2(prefix: string): string[] {
    if (this.isPermissionV2Enabled) {
      const commonAppPermissions = [
        'dashboard:read',
        'product-management:read',
        'setting:read',
        'error-list:read',
      ];
      let dashboardPermissions =
        this.permissionHelperService.getDashboardPermissions(undefined);
      dashboardPermissions = this.updatePermissions(
        this.isPermissionV2Enabled,
        dashboardPermissions,
      );
      const prefixedDashboardPermissions = dashboardPermissions.map(
        (perm) => `${prefix}.${perm}`,
      );
      const prefixedAppPermissions = commonAppPermissions.map(
        (perm) => `${prefix}.${perm}`,
      );
      return [...prefixedAppPermissions, ...prefixedDashboardPermissions];
    }
    return this.permissionHelperService.getAppReadPermissionsLists(prefix);
  }

  getZatcaPermissionsV2(): { [key in ZatcaAppPermissions]: boolean } {
    if (this.isPermissionV2Enabled) {
      const userPermissions = this.userPermissionsSubject$.value;
      const zatcaPermissions = {
        'zatca.dashboard.read': false,
        'zatca.dashboard.update': false,
        'zatca.settings.read': false,
        'zatca.error_list.read': false,
      };
      userPermissions.forEach((perm) => {
        if (perm.permission in zatcaPermissions) {
          zatcaPermissions[perm.permission] = true;
        }
      });
      return zatcaPermissions;
    }
    return this.permissionHelperService.getZatcaPermissions();
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  filterAvailableFieldsV2<T extends VariantLocationFields | LocationFields>(
    fields: {
      id: T;
      name: string;
      disabled: boolean;
      pendo: string;
    }[],
  ): {
    id: T;
    name: string;
    disabled: boolean;
    pendo: string;
  }[] {
    if (this.isPermissionV2Enabled) {
      const [
        hasRetailPricePermission,
        hasWholeSalePricePermission,
        hasBuyPricePermission,
        hasInitialCostPermission,
        hasAvailableQtyPermission,
      ] = [
        this.hasRetailPricePermission(),
        this.hasWholeSalePricePermission(),
        this.hasBuyPricePermissions(),
        this.hasInitialCostPermission(),
        this.hasAvailableQtyPermission(),
      ];

      return fields.filter((f) => {
        let isReadable = true;
        if (
          (f.id === VariantLocationFields.retailPrice &&
            !hasRetailPricePermission) ||
          (f.id === VariantLocationFields.wholeSalePrice &&
            hasWholeSalePricePermission) ||
          (f.id === VariantLocationFields.buyPrice && !hasBuyPricePermission) ||
          (f.id === VariantLocationFields.initialCost &&
            !hasInitialCostPermission) ||
          (f.id === LocationFields.QTY && !hasAvailableQtyPermission) ||
          (f.id === LocationFields.RETAIL_PRICE && !hasRetailPricePermission) ||
          (f.id === LocationFields.WHOLE_SALE_PRICE &&
            !hasWholeSalePricePermission) ||
          (f.id === LocationFields.BUY_PRICE && !hasBuyPricePermission)
        ) {
          isReadable = false;
        }
        return isReadable;
      });
    }
    return this.permissionHelperService.filterAvailableFields(fields);
  }

  private hasRetailPricePermission(): boolean {
    const retailPricePermissions = this.getRetailPricePermissions();
    const updatedPermissions = this.updatePermissions(
      this.isPermissionV2Enabled,
      retailPricePermissions,
    );
    return this.hasAtleastOnePermission(
      this.isPermissionV2Enabled,
      updatedPermissions,
    );
  }

  private hasWholeSalePricePermission(): boolean {
    const wholeSalePermissions = this.getWholeSalePricePermissions();
    const updatedPermissions = this.updatePermissions(
      this.isPermissionV2Enabled,
      wholeSalePermissions,
    );
    return this.hasAtleastOnePermission(
      this.isPermissionV2Enabled,
      updatedPermissions,
    );
  }

  private hasBuyPricePermissions(): boolean {
    const buyPricePermissions = this.getBuyPricePermission();
    const updatedPermissions = this.updatePermissions(
      this.isPermissionV2Enabled,
      buyPricePermissions,
    );
    return this.hasAtleastOnePermission(
      this.isPermissionV2Enabled,
      updatedPermissions,
    );
  }

  private hasAvailableQtyPermission(): boolean {
    const availableQtyPermissions = this.getAvailableQtyPermissions();
    const updatedPermissions = this.updatePermissions(
      this.isPermissionV2Enabled,
      availableQtyPermissions,
    );
    return this.hasAtleastOnePermission(
      this.isPermissionV2Enabled,
      updatedPermissions,
    );
  }

  private hasInitialCostPermission(): boolean {
    const initialCostPermissions = this.getInitialCostPermissions();
    const updatedPermissions = this.updatePermissions(
      this.isPermissionV2Enabled,
      initialCostPermissions,
    );
    return this.hasAtleastOnePermission(
      this.isPermissionV2Enabled,
      updatedPermissions,
    );
  }

  private getRetailPricePermissions = (): string[] => [
    CommonPolicies.PRODUCT_DETAILS_RETAIL_PRICE_READ,
    CommonPolicies.PRODUCT_DETAILS_RETAIL_PRICE_UPDATE,
  ];

  private getWholeSalePricePermissions = (): string[] => [
    CommonPolicies.PRODUCT_DETAILS_WHOLESALE_PRICE_READ,
    CommonPolicies.PRODUCT_DETAILS_WHOLESALE_PRICE_UPDATE,
  ];

  private getBuyPricePermission = (): string[] => [
    CommonPolicies.PRODUCT_DETAILS_BUY_PRICE_READ,
    CommonPolicies.PRODUCT_DETAILS_BUY_PRICE_UPDATE,
  ];

  private getAvailableQtyPermissions = (): string[] => [
    CommonPolicies.PRODUCT_AVAILABLE_QUANTITY_READ,
  ];

  private getInitialCostPermissions = (): string[] => [
    CommonPolicies.PRODUCT_DETAILS_AVERAGE_COST_READ,
  ];

  getDashboardPermissionsV2(isAccountingAppInstalled: boolean): string[] {
    const dashboardPermissions =
      this.permissionHelperService.getDashboardPermissions(
        isAccountingAppInstalled,
      );
    if (this.isPermissionV2Enabled) {
      return this.updatePermissions(true, dashboardPermissions);
    }
    return dashboardPermissions;
  }
}
