import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { LocalStorageService } from '../../core/services/local-storage.service';
import {
  APP_NAMES,
  CommonPolicies,
  LocalStorageKey,
  PermissionModules,
} from '../constants';
import {
  LocationFields,
  VariantLocationFields,
} from '../../inventory/products/components/create/product/simple-product/types';
import { SettingsService } from '../../users-settings/services/settings.service';
import { appNamesToPrefixes } from '../constants/permissions.constants';

export interface PermissionDTO {
  id: number;
  permission: string;
}

export interface Field<T> {
  id: T;
  name: string;
  disabled: boolean;
  pendo: string;
}

@Injectable()
export class PermissionHelperServiceV2 {
  private userPermissions: PermissionDTO[] = null;

  readonly dashboardPermissions: string[] = [
    'dashboard::',
    'dashboard::search',
    'dashboard:activity-logs:read',
    'dashboard:activity-logs:search',
    'dashboard:amount-collected-v2:read',
    'dashboard:amount-collected:read',
    'dashboard:average-items-per-sale:read',
    'dashboard:average-sales:read',
    'dashboard:gross-profit:read',
    'dashboard:inventory-value-v2:read',
    'dashboard:inventory-value:read',
    'dashboard:latest-orders-and-sales:read',
    'dashboard:logs:read',
    'dashboard:notifications:read',
    'dashboard:register-chart:read',
    'dashboard:revenue:read',
    'dashboard:sales-by-branch:read',
    'dashboard:sales-by-category:read',
    'dashboard:sales-by-day:read',
    'dashboard:sales-by-product:read',
    'dashboard:sales-by-salesman:read',
    'dashboard:sales-target:read',
    'dashboard:sales-transaction:read',
    'dashboard:top-5-cards:read',
    'dashboard:total-sales:read',
    'dashboard:transaction:read',
  ];

  readonly dashboardExpensePermissions: string[] = [
    'dashboard:expense:read',
    'dashboard:net-income-v2:read',
    'dashboard:net-income:read',
  ];

  readonly POSPermissionGroups = {
    retailPrice: [
      CommonPolicies.PRODUCT_DETAILS_RETAIL_PRICE_READ,
      CommonPolicies.PRODUCT_DETAILS_RETAIL_PRICE_UPDATE,
    ],
    wholeSalePrice: [
      CommonPolicies.PRODUCT_DETAILS_WHOLESALE_PRICE_READ,
      CommonPolicies.PRODUCT_DETAILS_WHOLESALE_PRICE_UPDATE,
    ],
    buyPrice: [
      CommonPolicies.PRODUCT_DETAILS_BUY_PRICE_READ,
      CommonPolicies.PRODUCT_DETAILS_BUY_PRICE_UPDATE,
    ],
    availableQty: [CommonPolicies.PRODUCT_AVAILABLE_QUANTITY_READ],
    initialCost: [CommonPolicies.PRODUCT_DETAILS_AVERAGE_COST_READ],
  };

  constructor(
    private localStorageService: LocalStorageService,
    private settingsService: SettingsService,
  ) {
    if (!this.userPermissions) {
      const permissions = this.localStorageService.getItem<PermissionDTO[]>(
        LocalStorageKey.Permissions,
      );

      if (permissions) {
        this.userPermissions = permissions;
        return;
      }
      this.fetchUserPermissions();
    }
  }

  /**
   * Fetches the user's permissions asynchronously.
   *
   * This function checks if the user's permissions are already fetched and stored.
   * If user permissions are not stored, this function retrieves the permissions asynchronously,
   * updates the local storage, and assigns the permissions to the `userPermissions` property.
   *
   * @returns nothing.
   */
  public async fetchUserPermissions(): Promise<void> {
    if (this.userPermissions) {
      return;
    }
    const { permissions } = await firstValueFrom(
      this.settingsService.getPermissions(),
    );
    this.userPermissions = permissions;
    this.localStorageService.setItem(LocalStorageKey.Permissions, permissions);
  }

  private async getPermissionByModuleName(
    moduleName: PermissionModules,
  ): Promise<string[]> {
    await this.fetchUserPermissions();
    return this.userPermissions
      ? this.userPermissions
          .filter((permissionObj) =>
            permissionObj.permission.startsWith(moduleName),
          )
          .map((permissionName) => permissionName.permission)
      : [];
  }

  /**
   * Checks if the user has at least one of the required permissions.
   *
   * This function fetches the user's permissions asynchronously and checks
   * if any of the permissions in the provided list are present in the user's permissions.
   *
   * @param requiredPermissions - An array of permission strings.
   * @returns A promise that resolves to true if at least one required permission is found,
   *          otherwise false.
   */
  public async hasAtLeastOnePermission(
    requiredPermissions: string[],
  ): Promise<boolean> {
    await this.fetchUserPermissions();
    return this.userPermissions
      ? this.userPermissions.some((permissionObj) =>
          requiredPermissions.includes(permissionObj.permission),
        )
      : false;
  }

  public async doesAppPermissionExist(
    appName: APP_NAMES,
    permissionSuffix: string,
  ): Promise<boolean> {
    const appPrefix = appNamesToPrefixes[appName];
    if (!appPrefix) return false;
    return this.hasAtLeastOnePermission([`${appPrefix}:${permissionSuffix}`]);
  }

  public async filterAvailableFields<
    T extends VariantLocationFields | LocationFields,
  >(fields: Field<T>[]): Promise<Field<T>[]> {
    const [
      hasRetailPricePermission,
      hasWholeSalePricePermission,
      hasBuyPricePermission,
      hasInitialCostPermission,
      hasAvailableQtyPermission,
    ] = await Promise.all([
      this.hasAtLeastOnePermission(this.POSPermissionGroups.retailPrice),
      this.hasAtLeastOnePermission(this.POSPermissionGroups.wholeSalePrice),
      this.hasAtLeastOnePermission(this.POSPermissionGroups.buyPrice),
      this.hasAtLeastOnePermission(this.POSPermissionGroups.initialCost),
      this.hasAtLeastOnePermission(this.POSPermissionGroups.availableQty),
    ]);

    const fieldToAvailabilityMap = {
      [VariantLocationFields.retailPrice]: hasRetailPricePermission,
      [VariantLocationFields.wholeSalePrice]: hasWholeSalePricePermission,
      [VariantLocationFields.buyPrice]: hasBuyPricePermission,
      [VariantLocationFields.initialCost]: hasInitialCostPermission,
      [LocationFields.QTY]: hasAvailableQtyPermission,
      [LocationFields.RETAIL_PRICE]: hasRetailPricePermission,
      [LocationFields.WHOLE_SALE_PRICE]: hasWholeSalePricePermission,
      [LocationFields.BUY_PRICE]: hasBuyPricePermission,
    };

    return fields.filter((field) => {
      const isAvailable = fieldToAvailabilityMap[field.id as string];
      return isAvailable ?? true;
    });
  }

  /**
   * Retrieves the dashboard permissions.
   *
   * This function combines the default dashboard permissions with additional permissions
   * specific to the expense feature, if it is installed.
   *
   * @param isExpenseInstalled - A boolean indicating if the expense feature is installed. Defaults to false.
   * @returns An array of dashboard permissions as strings.
   */
  public getDashboardPermissions = (isExpenseInstalled = false): string[] => [
    ...this.dashboardPermissions,
    ...(isExpenseInstalled ? this.dashboardExpensePermissions : []),
  ];

  /*
  ╔═════════════════════════════════════════════════════════════════════════════════╗
  ║                                                                                 ║
  ║                            !!! PENDING FUNCTIONS !!!                            ║
  ║                                                                                 ║
  ║══════════════════════════ getAppReadPermissionsListsV2 ═════════════════════════║
  ║                                                                                 ║
  ║  The use cases for this function are unclear.                                   ║
  ║                                                                                 ║
  ║   Current proposed methods:                                                     ║
  ║   - Use regex on complete permissions list                                      ║
  ║   - Update permissions read suffixes based on permissions v2                    ║
  ║                                                                                 ║
  ║═════════════════════════════ getZatcaPermissionsV2 ═════════════════════════════║
  ║                                                                                 ║
  ║  This function should be replaced by a directive.                               ║
  ║                                                                                 ║
  ║   Current proposed methods:                                                     ║
  ║   - Inform Eagles/Abdul Aala to update implementations                          ║
  ║                                                                                 ║
  ╚═════════════════════════════════════════════════════════════════════════════════╝
  */
}
